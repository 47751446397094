<template>
  <!-- 车主账号-->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="车主账号：">
        <el-input maxlength="15" placeholder="请输入车主账号" v-model="queryForm.account"
          @input="(e) => (queryForm.account = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="车牌号：">
        <el-input maxlength="8" placeholder="请输入车牌号" v-model="queryForm.plate_number"
          @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="车主管理">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="deletes" v-if="isShowBtn('635b4f17b10b00004400719a')">删除</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="create_time">
            <span>{{ validDateTime(row.create_time) }}</span>
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookParking(row)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column prop="account" label="车主账号"></el-table-column>
          <el-table-column prop="plate_number_str" label="车牌号"></el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.create_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookParking(scope.row)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount">
        </el-pagination>
      </div>
    </le-card>
    <!-- 查看详情 -->
    <lebo-dialog title="查看详情" :isShow="showOwnerManagement" width="60%" @close="showOwnerManagement = false" footerSlot closeOnClickModal>
      <searchOwnerManagement v-if="showOwnerManagement" :id="dialogOwnerManagementId"></searchOwnerManagement>
    </lebo-dialog>
  </div>
</template>

<script>
import { ownerList, ownerDelete } from '@/api/administrative'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import searchOwnerManagement from './components/searchOwnerManagement/index.vue'
export default {
  components: { searchOwnerManagement },
  data () {
    return {
      // 查询表单
      queryForm: {
        account: '',
        plate_number: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      deleteArray: [],
      TotalCount: 0,
      dialogOwnerManagementId: '',
      showOwnerManagement: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '车主账号',
            prop: 'account'
          }, {
            label: '车牌号',
            prop: 'plate_number_str'
          }, {
            label: '创建时间',
            prop: 'create_time'
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      console.log(this.queryForm)
    }
    this.requestList()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus'])
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    // this.requestPorkingList();
    // this.requestFindList();
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestList()
    },
    // 删除
    deletes () {
      var that = this
      if (this.deleteArray.length === 0) {
        this.$msg.warning('请选择要删除的条目！')
        return
      }
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          var list = []
          for (var i = 0; i < that.deleteArray.length; i++) {
            list.push(that.deleteArray[i]._id)
          }
          this.deleteItemRequest({ _ids: list })
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestList()
    // },
    // 查看
    lookParking (row) {
      this.dialogOwnerManagementId = row._id
      this.showOwnerManagement = true
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (array) {
      this.deleteArray = array
    },
    // // 选中的数据
    // selectionChange (array) {
    //   this.deleteArray = array
    // },
    async requestList () {
      try {
        const res = await ownerList(this.queryForm)
        // console.log("==--=ownerList=--==", res);
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      } catch (error) { }
    },
    async deleteItemRequest (data) {
      var params = this.queryForm
      try {
        const res = await ownerDelete(data)
        // console.log("==--=res-=", res);
        this.$msg.success('删除成功!')
        this.requestList(params)
      } catch (error) { }
    }
  }
}
</script>

<style scoped lang="less"></style>

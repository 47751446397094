<template>
  <!-- 车主管理 —— 查看详情 -->
  <div class="box-card" style="text-align: left">
    <!-- 主要内容 -->
    <el-form label-position="right" label-width="180px" :model="detailForm" class="detailForm" v-if="carListCount > 0">
      <el-form-item label="车主账号：">{{ detailForm.account }}</el-form-item>
      <div v-for="(item, index) in detailForm.carList" :key="index">
        <div class="title">车辆{{ index + 1 }}</div>
        <el-divider></el-divider>
        <div class="content_box">
          <el-row>
            <el-col :span="17">
              <el-form-item label="车牌号：">{{
                item.plate_number
              }}</el-form-item>
              <el-form-item label="车辆品牌：">{{ item.car_brand }}</el-form-item>
              <el-form-item label="车辆类型：">{{ item.car_type }}</el-form-item>
              <el-form-item label="车牌颜色：">{{
                getWayName("ColorType", item.color_type).replace("车", "")
              }}</el-form-item>
            </el-col>
            <el-col :span="7">
              <el-image style="width: 300px; height:200px" :src="item.driving_license" fit="cover"
                :preview-src-list="fnPreviewImg(item.driving_license)" :z-index="9000"></el-image>
              <div class="maskBox">车辆行驶证</div>
              <el-form-item>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <el-form label-position="right" label-width="180px" :model="detailForm" class="detailForm" v-else>
      <el-form-item label="车主账号：">{{ detailForm.account }}</el-form-item>
      <div>
        <div class="title">车辆1</div>
        <el-divider></el-divider>
        <div class="content_box">
          <el-row>
            <el-col :span="17">
              <el-form-item label="车牌号：">无</el-form-item>
              <el-form-item label="车辆品牌：">无</el-form-item>
              <el-form-item label="车辆类型：">无</el-form-item>
              <el-form-item label="车牌颜色：">无</el-form-item>
            </el-col>
            <el-col :span="7">
              <div class="image-slot">
                <i class="iconfont lebo-picerr"></i>
                <span style="margin-top: 5px">暂无车辆行驶证</span>
              </div>
              <div class="maskBox">车辆行驶证</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { ownerDetail } from '@/api/administrative'
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      detailForm: {},
      carinfo_id: '',
      carListCount: 0
    }
  },
  created () { },
  mounted () {
    this.carinfo_id = this.id
    this.requestDetails({ carinfo_id: this.carinfo_id })
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async requestDetails (params) {
      const res = await ownerDetail(params)
      // console.log('requestDetails---', res.Data);
      if (res && res.Code === 200) {
        this.detailForm = res.Data
        this.carListCount = res.Data.carList.length
      }
    },
    fnPreviewImg (img) {
      var arr = []
      arr.push(img)
      return arr
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.detailForm {
  max-height: 700px;
  overflow: hidden;
  overflow-y: auto;

  /deep/ .el-form-item {
    margin-bottom: 0;
  }

  /deep/ .content_box {
    // display: flex;
    align-items: start;
    justify-content: space-between;
    padding-right: 50px;

    .maskBox {
      width: 300px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: white;
      text-align: center;
      background-color: rgba(0, 0, 0, .5);
      margin-top: -7px;
    }
  }
}

.image-slot {
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f7fa;
  color: #999;
  .iconfont{
    font-size: 36px;
     color: #999;
  }
}
</style>
